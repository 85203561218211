import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/home/home";
import "./App.css";
import "./styles/style.sass";
import Connect from "./pages/connect/connect";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
    },
		{
      path: "/connect",
      element: <Connect />,
    },
    {
      path: "*",
      element: <Home />,
    },
		
  ]);
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
